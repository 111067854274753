<template>
  <div>
    <v-switch
      label="Show the Crop Suitablilty table?"
      v-model="fields.show_crop_suitability"
      color="green"
      class="mt-1 mb-4"
      inset
    ></v-switch>
    <v-card elevation="2" v-if="fields.show_crop_suitability" class="mb-6">
      <v-card-subtitle>Crop Suitability options</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-switch
          label="Is Suitable for Dairy?"
          v-model="fields.suitable_for_dairy"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Sheep?"
          v-model="fields.suitable_for_sheep"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Graze In Situ?"
          v-model="fields.suitable_for_graze_in_situ"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Zero Grazing?"
          v-model="fields.suitable_for_zero_grazing"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Beef?"
          v-model="fields.suitable_for_beef"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Pig?"
          v-model="fields.suitable_for_pig"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Ensile?"
          v-model="fields.suitable_for_ensile"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
        <v-switch
          label="Is Suitable for Lift and Store?"
          v-model="fields.suitable_for_lift_and_store"
          v-if="fields.show_crop_suitability"
          color="green"
          class="mt-1 mb-4"
          inset
        ></v-switch>
      </v-card-text>
    </v-card>
    <v-textarea
      label="Benefits *"
      v-model="fields.benefits"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('benefits')"
      :error-messages="errors['benefits']"
    ></v-textarea>
    <v-textarea
      label="Sowing Rates *"
      v-model="fields.sowing_rates"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('sowing_rates')"
      :error-messages="errors['sowing_rates']"
    ></v-textarea>
    <v-textarea
      label="Sowing Period *"
      v-model="fields.sowing_period"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('sowing_period')"
      :error-messages="errors['sowing_period']"
    ></v-textarea>
    <v-textarea
      label="Utilisation Period *"
      v-model="fields.utilisation_period"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('utilisation_period')"
      :error-messages="errors['utilisation_period']"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      required: true,
      default: null,
      validator: prop => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        show_crop_suitability: false,
        suitable_for_dairy: false,
        suitable_for_sheep: false,
        suitable_for_graze_in_situ: false,
        suitable_for_zero_grazing: false,
        suitable_for_beef: false,
        suitable_for_pig: false,
        suitable_for_ensile: false,
        suitable_for_lift_and_store: false,
        benefits: null,
        sowing_rates: null,
        sowing_period: null,
        utilisation_period: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.category !== null) {
      this.fields.show_crop_suitability = this.category.show_crop_suitability;
      this.fields.suitable_for_dairy = this.category.suitable_for_dairy;
      this.fields.suitable_for_sheep = this.category.suitable_for_sheep;
      this.fields.suitable_for_graze_in_situ = this.category.suitable_for_graze_in_situ;
      this.fields.suitable_for_zero_grazing = this.category.suitable_for_zero_grazing;
      this.fields.suitable_for_beef = this.category.suitable_for_beef;
      this.fields.suitable_for_pig = this.category.suitable_for_pig;
      this.fields.suitable_for_ensile = this.category.suitable_for_ensile;
      this.fields.suitable_for_lift_and_store = this.category.suitable_for_lift_and_store;
      this.fields.benefits = this.category.benefits;
      this.fields.sowing_rates = this.category.sowing_rates;
      this.fields.sowing_period = this.category.sowing_period;
      this.fields.utilisation_period = this.category.utilisation_period;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },
  },
};
</script>
